import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useClient } from "../utils/client";
import { useAuthContext } from "./useAuthContext";

export const extractFromToken = (token, property) => {
  const tokenParts = token?.split(".");
  if (tokenParts?.length !== 3) {
    throw new Error("Invalid token format");
  }
  const payload = JSON.parse(atob(tokenParts[1]));
  if (property === "role" || property === "email" || property === "houseName" || property === "id") {
    const value = payload[property];
    return value;
  } else {
    throw new Error("Invalid property requested");
  }
};

const useLogin = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { dispatch } = useAuthContext();
  const { mutateAsync: login, ...rest } = useMutation({
    mutationFn: (data) =>
      client("user/login", {
        method: "post",
        body: JSON.stringify(data),
        withoutAutorization: true,
      }),
    onSuccess: (json) => {
      // save the user to local storage
      localStorage.setItem("user", JSON.stringify(json));
      // extract the role from the token
      const userId = extractFromToken(json.token, "id");
      const role = extractFromToken(json.token, "role");
      const email = extractFromToken(json.token, "email");


      dispatch({ type: "LOGIN", payload: { ...json, role, email, userId } });
    },
    onError: (error) => {
      // Handle the error, for example, you can set it to the query client
      console.log('123')
      localStorage.removeItem("user");
      queryClient.setQueryData("loginError", error);
    },
  });
  return { ...rest, login };
};

const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = () => {
    // remove user for storage
    localStorage.removeItem("user");

    // dispatch logout action
    dispatch({ type: "LOGOUT" });
  };

  return { logout };
};

const useSignup = () => {
  const client = useClient();
  const { mutateAsync: signup, ...rest } = useMutation({
    mutationFn: (data) =>
      client("user/signup", {
        method: "post",
        body: JSON.stringify(data),
        withoutAutorization: true,
      }),
  });
  return { ...rest, signup };
};

export { useLogin, useLogout, useSignup };
