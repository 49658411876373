import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { useAuthContext } from "./hooks/useAuthContext";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import LayoutComponent from "./components/Layout";
import Error404 from "./components/Error404";
import WelcomeMessage from "./components/WelcomeMessage";
import HouseForm from "./components/HouseForm";
import ProtectedRoute from "./components/ProtectedRoute";
import ApartmentForm from "./components/ApartmentForm";
import ApartmentsPage from "./pages/Apartments";
import ApartmentPage from "./pages/Apartman";
// import GuestsPage from "./pages/GuestPage";
import ApartmentCategoriesPage from "./pages/ApartmentCategoriesPage";
import AvailableApartments from "./pages/AvailableApartments";
import ReservationsPage from "./pages/Reservations";

function AuthenticatedApp() {
  const { user } = useAuthContext();

  return (
    <Routes>
      <Route path="/" element={  <ApartmentsPage />} />
      <Route path="/login" element={<Navigate to="/" />} />
     <Route path="/house" element={
              <ProtectedRoute
              redirectPath="/house"
              isAllowed={!!user && user.role.includes("super-admin")}
              >
      <HouseForm />  </ProtectedRoute> } />
      <Route path="/admin-card"
              element={
                <ProtectedRoute
                  redirectPath="/"
                  isAllowed={!!user && user.role.includes("super-admin")}
                >
                 <WelcomeMessage />
                </ProtectedRoute>
              }
      />
     <Route path="/signup" element={
                  <ProtectedRoute
                  redirectPath="/signup"
                  isAllowed={!!user && user.role.includes("super-admin")}
                  >
                 <Signup />
              </ProtectedRoute> } /> 
      <Route
        path="/createApartment"
        element={
          <ProtectedRoute
            redirectPath="/"
            isAllowed={!!user && user.role.includes("admin")}
          >
            <ApartmentForm />
          </ProtectedRoute>
        }
      />
       <Route path="/reservations" element={<ReservationsPage />} />
       <Route path="/availableApartments" element={<AvailableApartments />} />
       <Route path="/apartmentCategories" 
        element={
          <ProtectedRoute
            redirectPath="/"
            isAllowed={!!user && user.role.includes("admin")}
          >
          <ApartmentCategoriesPage />
          </ProtectedRoute>
        }
        />
       {/* <Route path="/guests" element={<GuestsPage />} /> */}
       <Route path="/apartment/:id" element={<ApartmentPage />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

function UnauthenticatedApp() {
  return (
    <Routes> 
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

function App() {
  const { user } = useAuthContext();
  return (
    <div className="App">
      <BrowserRouter>
          {user ? ( <LayoutComponent> 
                    <div
                      className="mx-auto py-2 px-2 sm:px-6 lg:px-8 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500 hover:scrollbar-thumb-blue-600 scrollbar-track-blue-100 layout-wrapper"
                    >
                    <AuthenticatedApp />
                       </div>
                   </LayoutComponent> ) : (<UnauthenticatedApp />)}
       
      </BrowserRouter>
    </div>
  );
}

export default App;
