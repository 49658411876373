import React, { useState } from 'react';
import { Layout, Menu, Modal, theme } from 'antd';
import { useAuthContext } from "../hooks/useAuthContext";
import {
  AppstoreOutlined,
  LogoutOutlined,
  HomeOutlined,
  CalendarFilled,
  ScheduleFilled,
  RocketFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/login';

const { Content, Header } = Layout;

const LayoutComponent = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { user } = useAuthContext();

  const { logout } = useLogout(); 
  const [isModalVisible, setIsModalVisible] = useState(false); 

  const showLogoutConfirm = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    logout();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


// const moreItems = [
//   {
//     key: '4',
//     icon:  <AppstoreOutlined className='menu-icon' />,
//     label: <Link to="/apartmentCategories">Kategorije</Link>
//   },
//   {
//     key: '5',
//     icon:<ContactsFilled className='menu-icon' />,
//     label: <Link to="/guests">Gosti</Link>,
//   },
// ]

  const items = [
     {
      key: '0',
      icon: (
        <Link to="/admin-card">
          <RocketFilled className='menu-icon' />
        </Link>
      ),
      label: <Link to="/admin-card">Admin</Link>,
    },
    {
      key: '1',
      icon: (
          <Link to="/">
              <HomeOutlined className='menu-icon' />
          </Link>
      ),
      label: <Link to="/">Apartmani</Link>,
    },
    {
      key: '2',
      icon: (
          <Link to="/availableApartments">
              <CalendarFilled className='menu-icon' />
          </Link>
      ),
      label: <Link to="/availableApartments">Pretraga</Link>,
    },
    {
      key: '3',
      icon: (
          <Link to="/reservations">
              <ScheduleFilled className='menu-icon' />
          </Link>
      ),
      label: <Link to="/reservations">Rezervacije</Link>,
    },
    {
      key: '9',
      icon: (
          <Link to="/apartmentCategories">
              <AppstoreOutlined className='menu-icon' />
          </Link>
      ),
      label: <Link to="/apartmentCategories">Kategorije</Link>,
    },
    // {
    //   key: '9',
    //   icon: (
    //     <Dropdown menu={{ items: moreItems }} t trigger={['click']} className='bg-indigo-600'>
    //     <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}} className='create-link'>
    //       <ApiFilled className='menu-icon dropdown-menu-icons' />
    //       Dodatno
    //     </span>
    //   </Dropdown>
    //   ),
    // },
    {
      key: '11',
      icon: <LogoutOutlined className='menu-icon'  onClick={showLogoutConfirm}/>,
      label: <span onClick={showLogoutConfirm}>Izloguj se</span>,
    },
  ];
  const itemsToRender = user && user.role.includes("super-admin")
  ? items // Show all items for super-admins
  : items.filter(item => {
      if (user.role.includes("manager")) {
        // Filter out items for managers
        return item.key !== '0' && item.key !== '10' && item.key !== '3'; 
      }
      // Filter out only item.key === '0' for other roles
      return item.key !== '0'; 
    });

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', margin: 0 }}>
      <Layout style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Header
          style={{
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            justifyContent: 'center',
          }}
        >
          <Menu
            className='bg-indigo-600'
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['1']}
            items={itemsToRender}
            style={{
              flex: 1,
              minWidth: 0,
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              padding: 0,
              margin: 0,
            }}
          />
        </Header>
        <Content
          style={{
            overflowY: 'auto',
            flex: 1,
          }}
        >
          <div
            style={{
              minHeight: 380,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </div>
        </Content>

      </Layout>

      <Modal
          style={{
            top: 10,
          }}
        title="Potvrda odjave"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Da"
        cancelText="Ne"
      >
        <p>Da li ste sigurni da želite da izađete iz aplikacije?</p>
      </Modal>

      <style>{`
        @media (max-width: 767px) {
          .ant-menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0;
            height: 50px;
            flex: 1;
          }
          
          .ant-menu-item a {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 50px;
          }
          
          .ant-menu-item .anticon {
            font-size: 20px;
          }
        }
      `}</style>
    </Layout>
  );
};

export default LayoutComponent;
